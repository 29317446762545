import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Enter Error Code/Serial Number",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "page2",
    title: "Find Products",
    type: "item",
    icon: <Icon.Search size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/page2",
  },  
  {
    id: "page3",
    title: "Videos",
    type: "item",
    icon: <Icon.Youtube size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/demoVideo",
  },
  {
    id: "page5",
    title: "Spare Parts",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/spareParts",
  },
  {
    id: "page4",
    title: "Contact Us",
    type: "item",
    icon: <Icon.PhoneCall size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/contactUs",
  },
      
]

export default horizontalMenuConfig
