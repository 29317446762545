import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title:"  Enter Error Code/Serial Number",
    type: "item",
    icon: <img src={require('../assets/icn/icn_product_lists.png')} height="25px" />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "page2",
    title: "  Find Products",
    type: "item",
    icon: <img src={require('../assets/icn/icn_find_products.png')} height="25px" />,
    permissions: ["admin", "editor"],
    navLink: "/page2"
  },
  {
    id: "page3",
    title: "  Videos",
    type: "item",
    icon: <img src={require('../assets/icn/icn_downloaded_video.png')} height="25px" />,
    permissions: ["admin", "editor"],
    navLink: "/demoVideo"
  },
  {
    id: "page4",
    title: "  Contact Us",
    type: "item",
    icon: <img src={require('../assets/icn/icn_contactus.png')} height="25px" />,
    permissions: ["admin", "editor"],
    navLink: "/contactUs"
  },
  {
    id: "page6",
    title:"Spare Parts",
    type: "item",
    icon: <Icon.Package size={20} style={{ color:"#8ab35f"}} />,
    permissions: ["admin", "editor"],
    navLink: "/spareParts",
  },
  {
    id: "page5",
    title: "  Logout",
    type: "item",
    icon: <img src={require('../assets/icn/icn_logout.png')} height="25px" />,
    permissions: ["admin", "editor"],
    navLink: "/logOut",
  }
]

export default navigationConfig
